export interface IndexProps {
    [key: string]: any
}

export interface IndexMap {
    [key: string]: IndexProps
}

declare global {
    interface Window {
        __acmaSpaIndex: IndexMap;
        [key:string]: any
    }
}

const attributePrefix = "data-acma-";
const spaAttribute = "data-acma-spa";
const envAttribute = "data-acma-env";

const loadedScriptUrls: {[url:string]: Promise<void>} = {};

function loadScript(url: string) {
    url = url.toLowerCase();
    if(url in loadedScriptUrls) {
        return loadedScriptUrls[url];
    }
    let res = new Promise<void>(function(resolve, reject) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.onerror = function(err) {
            reject(err);
        };
        let temp = script as any;
        if (temp.readyState){  //IE
            temp.onreadystatechange = function(){
                if (temp.readyState == "loaded" || temp.readyState == "complete") {
                    temp.onreadystatechange = null;
                    resolve();
                }
            };
        } else {  //Others
            script.onload = function(){
                resolve();
            };
        }
    
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    })

    loadedScriptUrls[url] = res;
    return res;
}

function getJson(url: string) {
    return new Promise<IndexMap>(function(resolve, reject) {
        let request = new XMLHttpRequest();
        request.open("GET", url);
        request.send();
        request.onload = () => {
            if(request.status == 200) {
                resolve(JSON.parse(request.response));
            }
            else {
                reject(request.statusText);
            }
        }
    });
}

function renderSpa(c: Element, map: IndexMap)
{
    let spa = c.getAttribute(spaAttribute);
    if(spa in map) {
        let {urls, ...props} = map[spa];
        for(let attr of c.attributes)
        {
            if(attr.name.indexOf(attributePrefix) == 0)
            {
                if(attr.name != spaAttribute && attr.name != envAttribute) {
                    let propName = attr.name.substr(attributePrefix.length);
                    props[propName] = attr.value;
                }    
            }
        }
         (urls as string[]).reduce((p, u) => {
            return p.then(() => loadScript(u));
         }, Promise.resolve()).then(()=> {
            window[spa].renderSpa(c, props);
        });
    }
}

let containers = Array.prototype.slice.call(document.querySelectorAll(`[${spaAttribute}]`));
containers.forEach((c:Element) => {
    let env = c.getAttribute(envAttribute);
    if(env) {
        getJson(`https://aus${env}dgtlasa001.blob.core.windows.net/web/index.json`).then(json=> renderSpa(c, json));
    }
    else {
        renderSpa(c, window.__acmaSpaIndex);
    }
});